import React, { useState } from "react";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import Card from "./Card";
import TabLink from "./TabLink";

const SectionsMenu = ({ content, reverse, color }) => {
  const [selected, setSelected] = useState(0);
  const selectTab = (tabIndex) => {
    setSelected(tabIndex);
  };

  const colorPicker = (type) => {
    const bColor = color === "light" ? "default" : "darker";

    switch (type) {
      case "bg":
        return `bg-primary-${bColor} text-white`;
      case "b-r":
        return `border-r-2 border-r-primary-${bColor}`;
      case "b-l":
        return `border-l-2 border-l-primary-${bColor}`;
      case "b-b":
        return `border-b-2 border-b-primary-${bColor}`;
      default:
        return `text-primary-${bColor}`;
    }
  };

  return (
    <section
      className={`flex ${
        reverse ? "flex-row-reverse" : "flex-row"
      } relative max-w-6xl justify-around m-auto`}
    >
      <nav className=" flex-col w-1/3 hidden md:flex">
        <div
          className={`bg-none h-8 font-semibold  px-5 flex  ${
            reverse ? colorPicker("b-r") : colorPicker("b-l")
          } items-center ml-7 mr-10`}
        />
        <div className="">
          {content.map((el, index) => (
            <TabLink
              text={el.title}
              key={`${el.title}t${el.id}`}
              color={color}
              index={index}
              onClick={selectTab}
              selected={selected}
              className={selected === index ? colorPicker("bg") : "bg-none"}
            />
          ))}
          <div
            className={`bg-none h-[200px] font-semibold px-5 flex ${
              reverse ? colorPicker("b-r") : colorPicker("b-l")
            } ${colorPicker("b-b")} w-4/5 items-center ml-7`}
          />
        </div>
      </nav>
      <div className="md:w-3/4 relative">
        {content.map((el, index) => (
          <Card
            key={`${el.title}c${el.id}`}
            className={`${
              selected !== index && "hidden"
            } p-6 md:p-12 mb-8 md:mb-0 card-bg-fading`}
          >
            <article>
              <h2 className="text-3xl text-primary-darkest font-bold pb-4">
                {el.title}
              </h2>
              {el.content.map((cont) => {
                if (cont.text.includes("<ul>")) {
                  return (
                    <div
                      key={`cont-${cont.id}`}
                      className="text-primary-darkest text-lg text-left [&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2"
                      dangerouslySetInnerHTML={{ __html: cont.text }}
                    />
                  );
                }
                return (
                  <p
                    key={`cont-${cont.id}`}
                    className="text-primary-darkest text-lg text-left [&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2"
                    dangerouslySetInnerHTML={{ __html: cont.text }}
                  />
                );
              })}
            </article>
          </Card>
        ))}
        <div className="w-full h-16 flex justify-between items-end p-0 m-0">
          <a href="#services">
            <button
              type="button"
              className={`md:h-12 absolute bottom-0 left-0 flex justify-center items-center cursor-pointer w-1/4 h-16 md:w-16 rounded-md ${colorPicker(
                "bg"
              )} text-3xl`}
              onClick={() => {
                setSelected(selected === 0 ? content.length - 1 : selected - 1);
              }}
            >
              <CaretLeftOutlined />
            </button>
          </a>
          <a href="#services">
            <button
              type="button"
              className={`md:h-12 absolute bottom-0 right-0 flex justify-center items-center cursor-pointer w-1/4 h-16 md:w-16 rounded-md ${colorPicker(
                "bg"
              )} text-3xl`}
              onClick={() => {
                setSelected(selected === content.length - 1 ? 0 : selected + 1);
              }}
            >
              <CaretRightOutlined />
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionsMenu;
