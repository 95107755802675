import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import textData from "../data/testTexts";
import SectionsMenu from "../components/SectionsMenu";
import { SEO } from "../components/Seo";
import Accordion from "../components/Accordion";
import seoData from "../data/seoData.json";

const Services = () => (
  <Layout>
    <div className="absolute z-0 w-[100vw] max-w-[100%]">
      <div className="bgfilter" />
      <StaticImage
        src="../images/whypeople_imagen7.jpg"
        objectFit="cover"
        alt="whypeople"
        style={{
          width: "100vw",
          maxWidth: "100%",
          position: "fixed",
          zIndex: "-5",
          minHeight: "100vh",
        }}
      />
    </div>
    <section id="services" className="pb-8 min-h-[calc(100vh-10.5rem)]">
      <div className=" max-w-md lg:max-w-xl xl:max-w-3xl  3xl:max-w-4xl mx-auto py-12 md:py-20">
        <div className="flex flex-row relative z-5">
          <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
          <h1 className="text-4xl xl:text-5xl 2xl:text-6xl 4xl:text-7xl 7xl:text-8xl font-bold leading-none text-center text-primary-darkest w-[80%]">
            ¿Qué hacemos?
          </h1>
          <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
        </div>
      </div>

      <div className="hidden lg:block">
        <SectionsMenu content={textData.whatWeDo.list} color="light" />
      </div>

      <Accordion
        data={textData.whatWeDo.list}
        className="lg:hidden [&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2  z-[5] relative"
      />
    </section>
  </Layout>
);

export default Services;

export const Head = () => (
  <SEO
    title="Conoce todos nuestros servicios | WhyPeople"
    description="Hacemos Herramientas de diagnóstico, atracción de talento, retribución, evaluación del desempeño, formación, desarrollo, cultura y mucho más"
    keywords={["Servicios de WhyPeoople"]}
  >
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
