import React from "react";

const TabLink = ({ text, index, onClick, className }) => {
  const listNumber = (index + 1).toString();
  const paddedNumber = listNumber.padStart(2, 0);
  return (
    <div
      className={`${className} py-3 font-semibold  px-5 flex items-center mb-4 cursor-pointer`}
      onClick={() => {
        onClick(index);
      }}
    >
      {paddedNumber} <span className="pl-4">{text}</span>
    </div>
  );
};

export default TabLink;
